import { FIRST_NAME, FIRST_SURNAME, SECOND_NAME, SECOND_SURNAME } from '../../constants';

const Joi = require('joi');

const memberUpdateSchema = Joi.object().keys({
  input: {
    ldapData: {
      company: Joi.string(),
      turnAll: Joi.boolean()
    },
    roleData: {
      seniority: Joi.allow(),
      pod: Joi.allow(),
      isTransversal: Joi.boolean(),
      isDevops: Joi.boolean()
    },
    ogmioData: {
      firstName: Joi.string()
        .min(2)
        .required()
        .messages({
          'string.base': `${FIRST_NAME} should be a type of 'text'`,
          'string.empty': `${FIRST_NAME} cannot be an empty field`,
          'string.min': `${FIRST_NAME} should have a minimum length of {#limit}`,
          'any.required': `${FIRST_NAME} is a required field`
        }),
      secondName: Joi.string()
        .min(0)
        .messages({
          'string.base': `${SECOND_NAME} should be a type of 'text'`
        }),
      firstSurname: Joi.string()
        .min(3)
        .required()
        .messages({
          'string.base': `${FIRST_SURNAME} should be a type of 'text'`,
          'string.empty': `${FIRST_SURNAME} cannot be an empty field`,
          'string.min': `${FIRST_SURNAME} should have a minimum length of {#limit}`
        }),

      secondSurName: Joi.string()
        .min(0)
        .messages({
          'string.base': `${SECOND_SURNAME} should be a type of 'text'`
        }),
      email: Joi.string(),
      tools: Joi.array().items()
    }
  }
});

export default memberUpdateSchema;
