import * as React from 'react';
import '../tailwind.scss';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';

export default function IconInformation(props: TextErrorProps): JSX.Element | null {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  if (props.message.includes(props.value)) {
    return (
      <div>
        <ExclamationCircleIcon
          className="h-6 w-6 text-yellow-400"
          aria-hidden="true"
          data-tooltip-id="Tooltip_notification"
          data-tooltip-content={props.message}
        />
        <Tooltip id="Tooltip_notification" />
      </div>
    );
  }
  return null;
}

export interface TextErrorProps {
  message: string;
  value: string;
}
