import * as React from 'react';
import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { SubmitHandler } from 'react-hook-form';
import { FiEdit } from 'react-icons/fi';
import { useMutation } from 'react-query';
import 'reactjs-popup/dist/index.css';
import GenericForm, { FormSection } from '../../common-components/generic-form-component';
import { Notification3 } from '../../common-components/notifications';
import TitleComponent from '../../common-components/title-component';
import {
  CLOSE_MENU,
  MEMBER_WITH_ID,
  MESSAGE_UPDATE_SUCCESSFUL,
  MESSAGE_UPDATE_UN_SUCCESSFUL,
  UPDATE_MEMBER_RESULT
} from '../../constants';
import '../../tailwind.scss';
import { updateFormSections } from '../layouts/member_update_layout';
import { MemberInput } from '../member-interfaces';
import memberUpdateSchema from '../schemas/member-update-schema';
import { updateMemberService } from '../services/member-update-service';
import { enrichMember } from './member-utils';
import { Member } from './member-view-all-table';
import { DropDownItem } from '../../common-components/dropdown-component';
import { VuButton } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';


export default function MemberUpdateComponent(props: MemberActionProp): JSX.Element {
  const results = JSON.parse(localStorage.getItem('sharedDropdownValues')!);
  const podsArrayAll = results[0]?.data?.data?.getAllHefestoPodConstants;
  const seniorityArray = results[1]?.data?.data?.getAllHefestoSeniorityConstants;
  const transversalPodsArray = podsArrayAll?.filter((pod: DropDownItem) => pod.value.transversal);
  const podsArray = podsArrayAll?.filter((pod: DropDownItem) => pod.value.transversal == false);
  const [open, setOpen] = React.useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const updateMember = useMutation(updateMemberService, {
    onSuccess: (data: UpdateErrorResponse) => {
      setShowNotification(true);
      setLoading(false);
      console.log(data.data?.updateUserHefesto.errors);
      console.log(data.data?.updateUserHefesto.errors?.length);
      if (data.data?.updateUserHefesto.errors?.length === 0 || data.data?.updateUserHefesto.errors === undefined) {
        setIsSuccess(true);
        setNotificationMessage(`${MEMBER_WITH_ID} ${props.member.id} ${MESSAGE_UPDATE_SUCCESSFUL}`);
        // props.parentState(props.member.id);
      } else {
        const errorMessages = data.data?.updateUserHefesto.errors?.map(error => error.message).join(', ');
        setNotificationMessage(
          `${MEMBER_WITH_ID} ${props.member.id} ${MESSAGE_UPDATE_UN_SUCCESSFUL}, ${errorMessages}`
        );
        setIsSuccess(false);
      }
    }
  });

  const executeMutation = () => {
    setLoading(true);
    // updateMember.mutate({ auth: props.auth, member: props.member });
  };
  const updateForm: FormSection[] = updateFormSections(props.member, transversalPodsArray, podsArray, seniorityArray);

  const handleCloseWindow = () => {
    setOpen(false);
    const event = new CustomEvent('closeWindows', {
      detail: {
        closeWindows: 'true'
      }
    });
    window.dispatchEvent(event);
  };

  const onSubmit: SubmitHandler<MemberInput> = (data: MemberInput) => {
    setLoading(true);
    const enrichedData: Member = enrichMember(data, props.member, podsArray, transversalPodsArray, seniorityArray);
    if (memberUpdateSchema.validate(data).error === undefined) {
      enrichedData.authorization = props.ogmioToken || '';
      updateMember.mutate({ ...enrichedData });
    }
  };

  return (
    <>
      {showNotification && (
        <Notification3
          title={UPDATE_MEMBER_RESULT}
          message={notificationMessage}
          isSuccess={isSuccess}
          open={showNotification}
          setOpen={setShowNotification}
        />
      )}
      <button
        className="relative w-8 h-8 text-7xl bg-vulcanus_paynes_gray text-vulcanus_ghost_white-500 rounded-full flex justify-center items-center text-center p-5 shadow-xl hover:bg-vulcanus_delft_blue hover:text-vulcanus_ghost_white-500"
        onClick={() => setOpen(true)}
      >
        <span className="absolute left-0 top-0 m-2 ">
          <FiEdit className={'w-6 h-6 text-vulcanus_ghost_white-500'} />
        </span>
      </button>
      <Transition.Root show={open} as={React.Fragment}>
        <Dialog onClose={handleCloseWindow} className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-vulcanus_cool_gray-500 opacity-75" />
              </div>
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="z-20 sm:w-full md:w-4/6 lg:w-4/6 modal fade px-8 py-6 mx-4 mt-4 bg-vulcanus_ghost_white-500 shadow-lg fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 overflow-auto h-auto rounded-xl flex flex-col">
                <div className="flex justify-between items-start">
                  <TitleComponent size="l" value={'Update Member'} />
                  {/* <VuButton size="xs" text="X" variant="primary" onClick={handleCloseWindow}></VuButton> */}
                  <button
                    className="btn-approve px-4 py-1 rounded"
                    onClick={handleCloseWindow}
                  >
                    X
                  </button>
                </div>
                <GenericForm
                  formSections={updateForm}
                  onClose={onSubmit}
                  isLoading={loading}
                  schema={memberUpdateSchema}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export interface MemberActionProp {
  header: string;
  content: string;
  auth: string;
  member: Member;
  methodExecution?: () => void;
  ogmioToken?: string;
  parentState: (a: string) => void;
}

export interface UpdateErrorResponse {
  data?: {
    updateUserHefesto: {
      errors: DeleteResponseError[];
      user: User;
    };
  };
  errors?: GraphQLError[];
}

interface DeleteResponseError {
  message: string;
  type: string;
  data: ErrorData[];
}

interface ErrorData {
  field: string;
  message: string;
}

interface User {
  id: string;
  email: string;
  firstName: string;
  firstSurname: string;
  tools: Tool[];
  components: string;
}

interface Tool {
  name: string;
  value: string;
}

interface GraphQLError {
  path: null | string;
  locations: GraphQLErrorLocation[];
  message: string;
}

interface GraphQLErrorLocation {
  line: number;
  column: number;
  sourceName: null | string;
}
