import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { FormSection } from '../../common-components/generic-form-component';
import { DropDownItem } from '../../common-components/dropdown-component';
import {
  ARTIFACTORY,
  COMPANY_NAME,
  EMAIL,
  FIRST_NAME,
  FIRST_SURNAME,
  GITHUB,
  PLEASE_PROVIDE_MEMBER_INFORMATION,
  SECOND_NAME,
  SECOND_SURNAME,
  SONARQUBE,
  IS_TRANSVERSAL,
  POD,
  IS_DEVOPS,
  SENIORITY
} from '../../constants';
import { useToggleReducer } from '../../hooks/toggleReducer';
import { MemberInput } from '../member-interfaces';
import { extractToolValue } from '../member/member-utils';
import { Member } from '../member/member-view-all-table';

export const updateFormSections = (
  member: Member,
  transversalPodsArray: DropDownItem[],
  podsArray: DropDownItem[],
  seniorityArray: DropDownItem[]
): FormSection[] => {
  const {
    formState: { errors }
  } = useForm<MemberInput>();
  const [toggleStatus, turnOnAll, turnOffAll, toggleArtifactory, toggleGithub, toggleSonar] = useToggleReducer();
  const [artifactoryValue, setartifactoryValue] = useState(extractToolValue('artifactory', member.tools));
  const [emuValue, setemuValue] = useState(extractToolValue('emu', member.tools));
  const [sonarqubeValue, setsonarqubeValue] = useState(extractToolValue('sonarqube', member.tools));
  const [expectedArtifactoryValue, setExpectedArtifactoryValue] = useState<boolean>();
  const [expectedEmuValue, setExpectedEmuValue] = useState<boolean>();
  const [expectedSonarqubeValue, setExpectedSonarqubeValue] = useState<boolean>();
  const transversalRole = extractTransversalRoleValue(member, transversalPodsArray);
  const devopsRole = extractDevOpsRoleValue(member, podsArray, seniorityArray);
  const isTransversal = true ? transversalRole != '' : false;
  const isDevOps = devopsRole[0] === '' ? false : true;
  const [isDevOpsState, setDevOpsState] = useState<boolean>(isDevOps);
  const [isTransversalState, setTransversalOpsState] = useState<boolean>(isTransversal);

  let components = null;
  let ldapComponent = null;
  let company = '';

  try {
    components = JSON.parse(member.components);
    ldapComponent = components.ldap ? JSON.parse(components.ldap) : null;
    company = ldapComponent?.company || '';
  } catch (error) {
    // Handle the JSON parsing error here
    console.error('Error parsing JSON:', components);
  }

  window.addEventListener('closeWindows', closeEvent);

  function closeEvent(e: any) {
    setDevOpsState(isDevOps);
    setTransversalOpsState(isTransversal);
    setExpectedArtifactoryValue(undefined);
    setExpectedEmuValue(undefined);
    setExpectedSonarqubeValue(undefined);
  }

  return [
    {
      title: '',
      description: PLEASE_PROVIDE_MEMBER_INFORMATION,
      fields: [
        {
          changeMethod: () => {},
          route: 'input.ogmioData.firstName',
          id: FIRST_NAME,
          name: FIRST_NAME,
          label: '',
          type: 'text',
          autoComplete: 'family-name',
          placeholder: 'Enter your first name',
          required: true,
          initialValueString: member.firstName,
          section: 'personal-information'
        },
        {
          changeMethod: () => {},
          route: 'input.ogmioData.secondName',
          id: SECOND_NAME,
          name: SECOND_NAME,
          label: '',
          type: 'text',
          autoComplete: 'additional-name',
          placeholder: 'Enter your second name',
          required: false,
          initialValueString: member.secondName,
          section: 'personal-information'
        },
        {
          changeMethod: () => {},
          route: 'input.ogmioData.firstSurname',
          id: FIRST_SURNAME,
          name: FIRST_SURNAME,
          label: '',
          type: 'text',
          autoComplete: 'given-name',
          required: true,
          initialValueString: member.firstSurname,
          section: 'personal-information'
        },

        {
          changeMethod: () => {},
          route: 'input.ogmioData.secondSurName',
          id: SECOND_SURNAME,
          name: SECOND_SURNAME,
          label: '',
          type: 'text',
          autoComplete: 'family-name',
          required: false,
          initialValueString: member.secondSurName,
          section: 'personal-information'
        },
        {
          changeMethod: () => {},
          route: 'input.ogmioData.email',
          id: EMAIL,
          name: EMAIL,
          label: '',
          type: 'email',
          autoComplete: 'email',
          required: true,
          initialValueString: member.email,
          locked: true,
          section: 'personal-information'
        },
        {
          changeMethod: () => {},
          route: 'input.ldapData.company',
          id: COMPANY_NAME,
          name: COMPANY_NAME,
          label: '',
          type: 'text',
          autoComplete: 'off',
          required: true,
          initialValueString: company,
          locked: true,
          section: 'personal-information'
        },
        {
          changeMethod: toggleSonar,
          route: 'input.ogmioData.tools.0.value',
          placeholder: '',
          id: SONARQUBE,
          name: '',
          label: SONARQUBE,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          initialValueBoolean: sonarqubeValue,
          expectedValueBoolean: expectedSonarqubeValue,
          section: 'tool-information'
        },
        {
          changeMethod: toggleGithub,
          route: 'input.ogmioData.tools.1.value',
          placeholder: '',
          id: GITHUB,
          name: '',
          label: GITHUB,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          initialValueBoolean: emuValue,
          expectedValueBoolean: expectedEmuValue,
          section: 'tool-information'
        },
        {
          changeMethod: toggleArtifactory,
          route: 'input.ogmioData.tools.2.value',
          placeholder: '',
          id: ARTIFACTORY,
          name: '',
          label: ARTIFACTORY,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          initialValueBoolean: artifactoryValue,
          expectedValueBoolean: expectedArtifactoryValue,
          section: 'tool-information'
        },
        {
          changeMethod: () => {
            if (!isDevOpsState) {
              setTransversalOpsState(!isTransversalState);
            }
            if (isTransversalState) {
              setExpectedArtifactoryValue(undefined);
              setExpectedEmuValue(undefined);
              setExpectedSonarqubeValue(undefined);
            }
          },
          route: 'input.roleData.isTransversal',
          placeholder: '',
          id: IS_TRANSVERSAL,
          name: '',
          label: IS_TRANSVERSAL,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          colSpan: 2,
          locked: isDevOpsState,
          initialValueBoolean: isTransversal,
          section: 'role-information'
        },
        {
          changeMethod: event => {
            const tools = transversalPodsArray.filter((element: { id: string }) => element.id === event?.target.value);
            setExpectedArtifactoryValue(extractToolValue('artifactory', tools[0].value.tools));
            setExpectedEmuValue(extractToolValue('emu', tools[0].value.tools));
            setExpectedSonarqubeValue(extractToolValue('sonarqube', tools[0].value.tools));
          },
          route: 'input.roleData.pod',
          placeholder: '',
          id: POD,
          name: POD,
          label: POD,
          type: 'select',
          autoComplete: 'off',
          required: true,
          options: transversalPodsArray || [],
          initialValueSelect: transversalRole.toLowerCase(),
          isHidden: !isTransversalState,
          section: 'role-information'
        },
        {
          changeMethod: () => {
            if (!isTransversalState) {
              setDevOpsState(!isDevOpsState);
            }
            if (isDevOpsState) {
              setExpectedArtifactoryValue(undefined);
              setExpectedEmuValue(undefined);
              setExpectedSonarqubeValue(undefined);
            }
          },
          route: 'input.roleData.isDevops',
          placeholder: '',
          id: IS_DEVOPS,
          name: '',
          label: IS_DEVOPS,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          colSpan: 2,
          locked: isTransversalState,
          initialValueBoolean: isDevOps,
          section: 'role-information'
        },
        {
          changeMethod: () => {},
          route: 'input.roleData.pod',
          placeholder: '',
          id: POD,
          name: POD,
          label: POD,
          type: 'select',
          autoComplete: 'off',
          required: true,
          options: podsArray || [],
          isHidden: !isDevOpsState,
          initialValueSelect: devopsRole[0],
          section: 'role-information'
        },
        {
          changeMethod: () => {},
          route: 'input.roleData.seniority',
          id: SENIORITY,
          name: SENIORITY,
          label: SENIORITY,
          type: 'select',
          autoComplete: 'off',
          required: true,
          options: seniorityArray || [],
          isHidden: !isDevOpsState,
          initialValueSelect: devopsRole[1],
          section: 'role-information'
        }
      ],
      categories: [
        {
          id: 'personal-information',
          title: 'Personal Information',
          col_span: '2'
        },
        {
          id: 'tool-information',
          title: 'Tools Information',
          col_span: '3'
        },
        {
          id: 'role-information',
          title: 'Roles Information',
          col_span: '3'
        }
      ]
    }
  ];
};

export const extractTransversalRoleValue = (jsonData: any, transversalPodsArray: any) => {
  try {
    const role = jsonData.roles.find((t: { role: string }) => t.role === 'LEADERADL');
    if (role != null) {
      const transversalRole = transversalPodsArray.filter(
        (element: { value: any }) => element.value.singular === role.projectID.toLowerCase()
      );
      return transversalRole[0].value.plural;
    } else {
      const role = jsonData.roles.find((t: { role: string }) => t.role === 'SECURITYIT');
      if (role != null) {
        const transversalRole = transversalPodsArray.filter(
          (element: { value: any }) => element.value.singular === role.projectID.toLowerCase()
        );
        return transversalRole[0].value.plural;
      }
    }
    return '';
  } catch (e) {
    return '';
  }
};

export const extractDevOpsRoleValue = (jsonData: any, podsArray: any, seniorityArray: any) => {
  try {
    let projectID = '';
    let seniority = '';
    podsArray.forEach((key: any) => {
      const role = jsonData.roles.find((t: { projectID: string }) => t.projectID === key.value.singular);

      if (role != null) {
        seniorityArray.forEach((sen: any) => {
          if (role.role.toLowerCase() === sen.value.singular) {
            seniority = sen.value.plural;
          }
        });
        projectID = key.value.plural;
      }
    });
    return [projectID.toLowerCase(), seniority.toLowerCase()];
  } catch (e) {
    return ['', ''];
  }
};
