import { json } from 'stream/consumers';
import { BulbProps } from '../../common-components/bulb-component';
import { ControlledError, MemberInput, MemberResponse, NewControlledError } from '../member-interfaces';
import { Member } from './member-view-all-table';

export const extractErrorMessages = (response: MemberResponse): string => {
  const panicLDAPErrorMessages = response.panicLDAPError?.errors
    ? response.panicLDAPError?.errors.map((error: ControlledError) => `* ${error.message}`)
    : [];

  const newControlledErrorMessages = response.errors
    ? response.errors.map((error: NewControlledError) => `* ${error.message}`)
    : [];

  const createUserHefestoErrorMessages = response.data?.createUserHefesto?.errors
    ? response.data?.createUserHefesto?.errors.map((error: ControlledError) => `* ${error.message}`)
    : [];

  const errorMsgs = [...panicLDAPErrorMessages, ...newControlledErrorMessages, ...createUserHefestoErrorMessages];

  return errorMsgs.join('\n');
};
export const createToolTuples = (json: { input: { ogmioData: { tools: any } } }) => {
  const tools = json.input.ogmioData.tools;
  const toolNames = ['sonarqube', 'emu', 'artifactory'];
  const toolTuples: Array<{ name: string; value: string }> = [];

  toolNames.forEach((toolName, index) => {
    const toolValue = tools[index] && tools[index].value ? 'true' : 'false';
    toolTuples.push({ name: toolName, value: toolValue });
  });
  return toolTuples;
};
export const enrichMember = (
  memberInput: MemberInput,
  member: Member,
  podsArray: any,
  transversalPodsArray: any,
  seniorityArray: any
): Member => {
  const { firstName, secondName, firstSurname, secondSurName } = memberInput.input.ogmioData;
  member.firstName = firstName;
  member.secondName = secondName || '';
  member.firstSurname = firstSurname;
  member.secondSurName = secondSurName || '';

  const toolsToAdd = createToolTuples(memberInput);

  toolsToAdd.forEach(tool => {
    const existingToolIndex = member.tools.findIndex(t => t.name === tool.name);
    if (existingToolIndex !== -1) {
      // Tool already exists, update the value
      member.tools[existingToolIndex].value = tool.value;
    } else {
      // Tool doesn't exist, push it to member.tools
      member.tools.push(tool as BulbProps);
    }
  });

  member = verifyTransversalDevOpsRoles(seniorityArray, podsArray, transversalPodsArray, memberInput, member);
  return member;
};

const verifyTransversalDevOpsRoles = (
  seniorityArray: any,
  podsArray: any,
  transversalPodsArray: any,
  memberInput: MemberInput,
  member: Member
) => {
  member = deleteTransversalsRoleValue(member);
  deleteDevOpsRoleValue(member, podsArray);
  if (memberInput.input.roleData.isDevops) {
    const devOpsPod = podsArray.find((t: { id: string }) => t.id === memberInput.input.roleData.pod);
    const devOpsSeniority = seniorityArray.find((t: { id: string }) => t.id === memberInput.input.roleData.seniority);
    member.roles = [
      ...member.roles,
      { role: devOpsSeniority.value.singular.toUpperCase(), projectID: devOpsPod.value.singular }
    ];
  }
  if (memberInput.input.roleData.isTransversal) {
    const transversalPod = transversalPodsArray.find((t: { id: string }) => t.id === memberInput.input.roleData.pod);
    try {
      member.roles = [
        ...member.roles,
        {
          role: transversalPod.value.singular === 'securityit' ? 'SECURITYIT' : 'LEADERADL',
          projectID:
            transversalPod.value.singular === 'securityit'
              ? transversalPod.value.singular
              : transversalPod.value.singular.toUpperCase()
        }
      ];
    } catch (e) {
      member.roles = [
        {
          role: transversalPod.value.singular === 'securityit' ? 'SECURITYIT' : 'LEADERADL',
          projectID:
            transversalPod.value.singular === 'securityit'
              ? transversalPod.value.singular
              : transversalPod.value.singular.toUpperCase()
        }
      ];
    }
  }
  return member;
};

const deleteTransversalsRoleValue = (member: any) => {
  try {
    const role = member.roles.filter((t: { role: string }) => t.role != 'LEADERADL' && t.role != 'SECURITYIT');
    member.roles = role;
  } catch (e) {}
  return member;
};

const deleteDevOpsRoleValue = (member: any, podsArray: any) => {
  try {
    podsArray.forEach((key: any) => {
      const role = member.roles.filter((t: { projectID: string }) => t.projectID != key.value.singular);
      member.roles = role;
    });
  } catch (e) {}
  return member;
};

export const extractToolValue = (toolName: string, jsonData: any) => {
  const tool = jsonData.find((t: { name: string }) => t.name === toolName);
  return tool ? tool.value === 'true' : false;
};
