import * as React from 'react';
import '../tailwind.scss';

export default function TooltipLabelComponent(props: TooltipLabelProps): JSX.Element {
  return (
    <span
      className="ml-3 text-sm font-medium text-bg-vulcanus_cool_gray-500"
      data-bs-toggle="tooltip"
      title={props.message}
    >
      {props.value}
    </span>
  );
}
export interface TooltipLabelProps {
  value: string;
  message: string;
}
