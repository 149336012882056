import * as React from 'react';
import BulbComponent, { BulbProps } from '../../common-components/bulb-component';
import RowInfoComponent from '../../common-components/row-info-component';
import {
  COMPANY_NAME,
  FIRST_NAME,
  FIRST_SURNAME,
  GITHUB_USER_NAME,
  ROLES_TITLE,
  SECOND_NAME,
  SECOND_SURNAME,
  SERVICES_TITLE
} from '../../constants';
import '../../tailwind.scss';
import { Member } from './member-view-all-table';
import { removeUserToGroupService, UserToGroup, RemoveUserToGroup } from '../services/member-remove-group-service';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import MyModal from '../../common-components/modals';

export const MemberExpandableComponent = (props: MemberExpandableProps) => {
  const queryClient = useQueryClient();
  const fields = [
    { name: FIRST_NAME, value: props.member.firstName },
    { name: SECOND_NAME, value: props.member.secondName },
    { name: FIRST_SURNAME, value: props.member.firstSurname },
    { name: SECOND_SURNAME, value: props.member.secondSurName },
    { name: COMPANY_NAME, value: props.member.companyName },
    { name: GITHUB_USER_NAME, value: props.member.githubEmuUser }
  ];
  const tools = props.member.tools === undefined ? [] : props.member.tools;
  const roles = props.member.roles === null ? [] : props.member.roles;

  const [selectedRole, setSelectedRole] = useState<{ role: string, projectID: string } | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveRole = (role: string, projectID: string) => {
    setSelectedRole({ role, projectID });
    setIsDialogOpen(true);
  };

  const removeUserGroup = useMutation((removeGroup: RemoveUserToGroup) => removeUserToGroupService(removeGroup), {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: async response => {
      setIsLoading(false);
      await queryClient.invalidateQueries('memberData');

      if (response.data.removeUserToGroup?.statusCode === 200) {
        setIsDialogOpen(false);
        toast.success('Operación realizada con éxito');
      } else if (response.data.removeUserToGroup?.errors.length > 0) {
        toast.custom(
          t => (
            <div
              className={`${
                t.visible ? 'animate-enter' : 'animate-leave'
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 overflow-hidden`}
            >
              <div className="flex-1 w-auto p-4">
                <div className="flex items-start">
                  <div className="ml-3 flex-1">
                    <p className="col-span-3 text-sm font-semibold text-red-950">Errores al aprobar la solicitud: </p>
                    <div className="mt-2 text-sm text-gray-500 space-y-2 w-auto">
                      {response.data.removeUserToGroup?.errors.map((error: any, index: number) => {
                        const [errorType, message] = error.message
                          .replace('[ERROR]', '')
                          .split('::')
                          .map((part: string) => part.trim());
                        return (
                          <div key={index} className="grid grid-cols-3 border-b py-2">
                            <p className="font-bold text-red-950">{errorType}</p>
                            <p className="col-span-2 text-sm text-gray-500 ">{message}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="border border-transparent h-2 w-2 rounded-full p-4 flex items-center justify-center text-sm font-medium bg-red-700 hover:bg-red-800 text-white"
                >
                  X
                </button>
              </div>
            </div>
          ),
          { duration: 38000, style: { maxWidth: 'none' } }
        );
      } else {
        toast.error('Ocurrió un error inesperado', { duration: 8000 });
      }
    },
    onError: () => {
      setIsLoading(false);
      toast.error('An error occurred while removing the role.');
    }
  });

  const confirmRemoveRole = async () => {
    if (selectedRole) {
      try {
        const payload: UserToGroup = {
          id: props.member.id,
          email: props.member.email,
          layerId: "LAYER_" + selectedRole.projectID.replace("-", "_").toUpperCase(),
          roleId: selectedRole.role,
          tools: props.member.tools
        };

        const removeGroup: RemoveUserToGroup = {
          userToGroup: payload,
          ogmioToken: props.ogmioToken
        };
        setIsLoading(true);
        removeUserGroup.mutate({ ...removeGroup });
      } catch (error) {
        console.error('Error removing role:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={'container pb-8'} key={`${props.member.email}-expandable`}>
      <span className={'container my-3'}>
        <h3 className={`basis-full justify-center text-xs font-bold text-center`}>{SERVICES_TITLE}</h3>
        {tools.map((tool: BulbProps) => (
          <BulbComponent
            key={`${props.member.email}-${tool.name}`}
            value={tool.value}
            name={tool.name}
            components={props.member.components}
          />
        ))}
      </span>
      <span className={'container mb-3'}>
        <h3 className={`basis-full justify-center text-xs font-bold text-center`}>{ROLES_TITLE}</h3>
        <div className="container mx-auto">
          <div className="grid grid-cols-3 auto-rows-max gap-4 items-center">
            {roles.map(({ role, projectID }) => (
              <div key={`${role}-${projectID}`} className="relative col-span-1 row-span-1 bg-slate-100 p-2 rounded-xl flex items-center">
                <span className="whitespace-nowrap text-small overflow-hidden overflow-ellipsis pr-2">{`${role}@${projectID}`}</span>
                <button
                  className="ml-auto text-white text-xs px-2 rounded-full bg-red-700 hover:bg-red-800"
                  onClick={() => handleRemoveRole(role, projectID)}
                >
                  x
                </button>
              </div>
            ))}
          </div>
        </div>
      </span>
      {fields.map(({ name, value }) => (
        <RowInfoComponent key={name} tittle={name} value={value} />
      ))}

      <MyModal
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        description="Are you sure you want to remove this role?"
        actionButtonText="Yes, remove it"
        secondaryButtonText="Cancel"
        title="Remove Role"
        executeMutation={confirmRemoveRole}
        loading={isLoading}
      />
    </div>
  );
};

export interface MemberExpandableProps {
  member: Member;
  ogmioToken: string;
}

export default React.memo(MemberExpandableComponent);

export interface RemoveToGroupResponse {
  data: {
    removeUserToGroup?: {
      errors: ControlledError[];
      statusCode?: number;
    };
  };
}

export interface ControlledError {
  message: string;
  type: string;
}
