import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import BaseComponent from './components/base-component';
import './tailwind.scss';
import { Toaster } from 'react-hot-toast';

export default function Root(): JSX.Element {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="top-center" reverseOrder={false} />
      <BaseComponent />
    </QueryClientProvider>
  );
}
