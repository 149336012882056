import axios from 'axios';
import { Member } from '../member/member-view-all-table';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/graphql'
  }
});

const deleteUserMutation = `mutation deleteUserHefesto($input: DeleteUserHefestoInput!) {
  deleteUserHefesto(input: $input) {
    errors {
      message
      type
      data {
        field
        message
      }
    }
    user {
        id
      email
      firstName
      firstSurname
      id
      tools {
        name
        value
      }
      components
    }
    statusCode
  }
}`;

export const deleteMemberService = async (props: MembersDeleteProps) => {
  const graphql = JSON.stringify({
    query: deleteUserMutation,
    variables: { input: props.member }
  });
  const url = process.env.GRAPHQLURL || '';
  try {
    const { data } = await axiosInstance.post(url, graphql, {
      headers: {
        Authorization: props.auth
      }
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export interface MembersDeleteProps {
  auth: string;
  member: Member;
}
