import axios from 'axios';

export const removeUserToGroupService = async (props: RemoveUserToGroup) => {
  const config = {
    headers: {
      Authorization: props.ogmioToken,
      'Content-Type': 'application/json'
    }
  };
  const graphql = JSON.stringify({
    query: removeUserToGroup,
    variables:  {input: {
      id: props.userToGroup.id,
      email: props.userToGroup.email,
      layerId: props.userToGroup.layerId,
      roleId: props.userToGroup.roleId,
      tools: props.userToGroup.tools
    }
  }
  });

  const URL = process.env.GRAPHQLURL !== undefined ? process.env.GRAPHQLURL : '';
  const { data: response } = await axios.post(URL, graphql, config);
  return response;
};

const removeUserToGroup = `
mutation removeUserToGroup($input: UserToGroupInput!) {
  removeUserToGroup(input: $input) {
    errors {
      message
      type
    }
    statusCode
  }
}
`;

export interface RemoveUserToGroup {
  userToGroup: UserToGroup;
  ogmioToken: string;
}

export interface UserToGroup {
  id: string,
  email: string, 
  roleId: string, 
  layerId: string, 
  tools: any
}