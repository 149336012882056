import * as React from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import '../tailwind.scss';
import { VuInput } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';

export default function InputComponent<T>(props: InputProps<T>): JSX.Element {
  return (
    <div className="mt-4">
      <label className="text-vulcanus_cool_gray-500">{props.name + (props.required === true ? '*' : '')}</label>
      <VuInput
        required={props.required}
        variant="primary"
        placeholder={props.placeholder}
        type={props.type}
        disabled={props.locked}
        defaultValue={props.initialValue}
        register={props.register}
        route={props.route}
      />
    </div>
  );
}

export interface InputProps<T> {
  route: Path<T>;
  name: string;
  register: UseFormRegister<T>;
  locked: boolean;
  initialValue: string;
  required?: boolean;
  placeholder: string;
  type: any;
}
