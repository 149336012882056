import * as React from 'react';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useMutation } from 'react-query';
import 'reactjs-popup/dist/index.css';
import MyModal from '../../common-components/modals';
import { Notification3 } from '../../common-components/notifications';
import {
  DELETE_MEMBER_RESULT,
  MEMBER_WITH_ID,
  MESSAGE_CONFIRM_DELETE,
  MESSAGE_DELETE_UN_SUCCESSFUL,
  THIS_ACTION_CANT_BE_UNDONE
} from '../../constants';
import '../../tailwind.scss';
import { deleteMemberService } from '../services/ogmio-delete-member-service';
import { Member } from './member-view-all-table';

const DELETE_TITTLE = 'Delete Member';
const DELETE_PRIMARY_BUTTON_TEXT = 'Delete';
const DELETE_SECONDARY_BUTTON_TEXT = 'Cancel';

export default function MemberDeleteComponent(props: MemberActionProp): JSX.Element {
  const [open, setOpen] = React.useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const deleteMember = useMutation(deleteMemberService, {
    onSuccess: (data: DeleteResponse) => {
      setLoading(false);
      if (data?.data?.deleteUserHefesto?.errors?.length === 0) {
        props.parentState(props.member.id);
      } else {
        setShowNotification(true);
        setNotificationMessage(`${MEMBER_WITH_ID} ${props.member.id} ${MESSAGE_DELETE_UN_SUCCESSFUL},
         ${data.data.deleteUserHefesto.errors.map((error: DeleteResponseError) => error.message)}`);
        setIsSuccess(false);
      }
    },
    onError: (data: DeleteResponse) => {
      // this case actually never happens
      setNotificationMessage(`${MEMBER_WITH_ID} ${props.member.id} ${MESSAGE_DELETE_UN_SUCCESSFUL},
       ${data.data.deleteUserHefesto.errors.map((error: DeleteResponseError) => error.message)}`);
      setShowNotification(true);
      setIsSuccess(false);
    }
  });

  const executeMutation = () => {
    setLoading(true);
    deleteMember.mutate({ auth: props.auth, member: props.member });
  };

  return (
    <>
      <button
        className="relative w-8 h-8 text-7xl bg-vulcanus_paynes_gray text-vulcanus_ghost_white-500 rounded-full
         flex justify-center items-center text-center p-5 shadow-xl hover:bg-vulcanus_delft_blue hover:text-white"
        onClick={() => setOpen(true)}
      >
        <span className="absolute left-0 top-0 m-2 ">
          <FiTrash2 className={'w-6 h-6 vulcanus_ghost_white-500'} />
        </span>
      </button>
      {showNotification && (
        <Notification3
          title={DELETE_MEMBER_RESULT}
          message={notificationMessage}
          isSuccess={isSuccess}
          open={showNotification}
          setOpen={setShowNotification}
        />
      )}
      {open && (
        <MyModal
          open={open}
          setOpen={setOpen}
          description={`${MESSAGE_CONFIRM_DELETE} ${MEMBER_WITH_ID} ${props.member.id}? ${THIS_ACTION_CANT_BE_UNDONE}`}
          actionButtonText={DELETE_PRIMARY_BUTTON_TEXT}
          secondaryButtonText={DELETE_SECONDARY_BUTTON_TEXT}
          title={DELETE_TITTLE}
          executeMutation={executeMutation}
          loading={loading}
        />
      )}
    </>
  );
}

export interface MemberActionProp {
  header: string;
  content: string;
  auth: string;
  member: Member;
  methodExecution?: () => void;
  parentState: (a: string) => void;
}

export interface DeleteResponse {
  data: {
    deleteUserHefesto: {
      errors: DeleteResponseError[];
      user: User;
    };
  };
}

interface DeleteResponseError {
  message: string;
  type: string;
  data: ErrorData[];
}

interface ErrorData {
  field: string;
  message: string;
}

interface User {
  id: string;
  email: string;
  firstName: string;
  firstSurname: string;
  tools: Tool[];
  components: string;
}

interface Tool {
  name: string;
  value: string;
}
