import { VuCustomLoader } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Fragment, useRef, useState } from 'react';
import * as React from 'react';
import CustomLoader from './custom-loader';
import { VuButton } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';

export default function MyModal(props: MyModalProps): JSX.Element {
  const { open, setOpen, loading } = props;
  const cancelButtonRef = useRef(null);
  const handlePrimaryButtonClick = () => {
    props.executeMutation();
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="relative z-10">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-vulcanus_cool_gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-vulcanus_ghost_white-500 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-vulcanus_ghost_white-500 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-vulcanus_delft_blue sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-vulcanus_ghost_white-500" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title>
                          <h3 className="text-base font-semibold leading-6 text-vulcanus_delft_blue">{props.title}</h3>
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-vulcanus_cool_gray-500">{props.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-vulcanus_cool_gray-700 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <div className="flex justify-center items-center">
                        <VuCustomLoader height={50} width={50} color={'vulcanus_ghost_white-500'} />
                      </div>
                    ) : (
                      <>
                        <VuButton
                          size="sm"
                          variant="primary"
                          text={props.actionButtonText}
                          onClick={handlePrimaryButtonClick}
                          disabled={loading}
                        />
                        <VuButton
                          size="sm"
                          variant="secondary"
                          text={props.secondaryButtonText}
                          onClick={() => setOpen(false)}
                        />
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

interface GenericModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description: string;
  icon: 'warning' | 'success';
  primaryButtonText: string;
  secondaryButtonText: string;
  onPrimaryButtonClick?: () => void;
}

export function GenericModal(props: GenericModalProps): JSX.Element {
  const { open, setOpen, title, description, icon, primaryButtonText, secondaryButtonText, onPrimaryButtonClick } =
    props;
  const cancelButtonRef = useRef(null);

  const handlePrimaryButtonClick = () => {
    onPrimaryButtonClick && onPrimaryButtonClick();
    setOpen(false);
  };

  const IconComponent = icon === 'warning' ? ExclamationTriangleIcon : CheckIcon;
  const iconBgColor = icon === 'warning' ? 'bg-red-100' : 'bg-green-100';
  const iconColor = icon === 'warning' ? 'text-red-600' : 'text-green-600';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog initialFocus={cancelButtonRef} onClose={setOpen} className="relative z-10">
        <div>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${iconBgColor}`}>
                      <IconComponent className={`h-6 w-6 ${iconColor}`} aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        <h3>{title}</h3>
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={handlePrimaryButtonClick}
                    >
                      {primaryButtonText}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      {secondaryButtonText}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export interface MyModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  executeMutation: () => void;
  description: string;
  actionButtonText: string;
  secondaryButtonText: string;
  title: string;
  loading: boolean;
}

// <div>
//       <button onClick={() => setOpen(true)}>Open Modal</button>
//       <GenericModal
//         open={open}
//         setOpen={setOpen}
//         title="Payment successful"
//         description="This is a long textThis is a long textThis is a long textThis is a long textThis is a long textThis is a long text"
//         icon="success"
//         primaryButtonText="Deactivate"
//         secondaryButtonText="Cancel"
//         onPrimaryButtonClick={() => console.log('Deactivate button clicked')}
//       />
//     </div>
