import * as React from 'react';
import { useState } from 'react';
import '../tailwind.scss';

const GREEN_COLOR = 'bg-green-200';
const RED_COLOR = 'bg-red-200';
const YELLOW_COLOR = 'bg-yellow-200';

export default function BulbComponent(props: BulbProps): JSX.Element {
  const [bulbState, _] = useState<Bulb>({
    color:
    (Object.keys(JSON.parse(props.components)).indexOf(props.name.toLowerCase())) != -1
        ? GREEN_COLOR
        : props.value === 'true'
        ? RED_COLOR
        : YELLOW_COLOR
  });

  return (
    <div className="pt-4 mx-5  flex" key={props.components}>
      <span
        className={`inline-flex items-center rounded-md ${bulbState.color} px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-vulcanus_periwinkle-600/20`}
      >
        {props.name == 'emu' ? 'github' : props.name}
      </span>
    </div>
  );
}

export interface BulbProps {
  name: string;
  value: string;
  components: string;
}

export interface Bulb {
  color: string;
}
