import axios from 'axios';

const GET_ALL_USERS_QUERY = `query getAllUsers($input: PaginationInput){
  getAllUsers(input: $input){
    users {
        id
    firstName
    secondName
    firstSurname
    secondSurName
    email
    roles{
        role
        projectID
    }
    tools{
             name
             value
        }
    components
audit {
createdAt
createdBy
updatedAt
updatedBy
}
    githubUser
    githubEmuUser
    address
    phones    }
    total
    page
    limit
    nextPage
    prevPage
  }
}`;

export const membersGetAllService = async (membersGetAllProps: MembersGetAllProps) => {
  const config = {
    headers: { Authorization: membersGetAllProps.auth, 'Content-Type': 'application/graphql' }
  };
  const graphql = JSON.stringify({
    query: GET_ALL_USERS_QUERY,
    variables: {
      input: {
        limit: membersGetAllProps.p_limit,
        page: membersGetAllProps.page,
        filter: membersGetAllProps.filter
      }
    }
  });

  const ULR = process.env.GRAPHQLURL !== undefined ? process.env.GRAPHQLURL : '';
  const { data: response } = await axios.post(ULR, graphql, config);
  return response;
};

export interface MembersGetAllProps {
  auth: string;
  page: number;
  p_limit: number;
  filter: {
    key: string;
    value: string;
  };
}
