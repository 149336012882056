import { ChangeEvent } from 'react';
import * as React from 'react';
import '../tailwind.scss';
import { DropDownItem } from './dropdown-component';

export default function SimpleDropdownComponent(props: DropDownProps): JSX.Element {
  return (
    <select
      onChange={(event: ChangeEvent<HTMLSelectElement>) => props.setCurrentState(event.target.value)}
      value={props.currentState}
      aria-label="Disabled select example"
      className="border border-gray-300 relative px-4 py-2 rounded-r-md focus:ring-vulcanus_delft_blue-700 focus:border-vulcanus_ring-vulcanus_delft_blue-700 text-black"
    >
      {props.items.map((item: DropDownItem) => (
        <option value={item.id} key={item.id}>
          {' '}
          {item.value.plural}{' '}
        </option>
      ))}
    </select>
  );
}

export interface DropDownProps {
  name: string;
  currentState: any;
  setCurrentState: any;
  items: DropDownItem[];
}
