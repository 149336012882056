import * as React from 'react';
import { Grid } from 'react-loader-spinner';
import '../tailwind.scss';

interface CustomLoaderProps {
  height: string;
  width: string;
  color: string;
}

export default function CustomLoader({ height, width, color }: CustomLoaderProps): JSX.Element {
  return (
    <Grid
      visible={true}
      height={height}
      width={width}
      color={color}
      ariaLabel="grid-loading"
      radius="12.5"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
}
