import * as React from 'react';
import { useQueries } from 'react-query';
import '../tailwind.scss';
import MemberViewAllTable from './member/member-view-all-table';
import { ogmioTokenService } from './services/ogmio-token-service';
import { useState } from 'react';
import { ogmioGetAllPodServiceRest } from './services/ogmio-get-pod-service';
import { ogmioGetAllSeniorityServiceRest } from './services/ogmio-get-seniority-service';

export default function BaseComponent(): JSX.Element {
  const [enabled, setEnabled] = useState(true);
  const [ogmioToken, setOgmioToken] = useState('');
  window.addEventListener('userToken', myEventHandlingFunction);

  function myEventHandlingFunction(e: any) {
    setOgmioToken(e.detail.tokenValue);
  }
  if (ogmioToken === '') {
    window.dispatchEvent(new CustomEvent('requestToken'));
  }
  const results = useQueries([
    {
      queryKey: `${ogmioToken} Pod`,
      queryFn: ogmioGetAllPodServiceRest,
      enabled: (enabled && ogmioToken != '') ? true : false,
      onSuccess: (data: any) => {
        if (data.data.getAllHefestoPodConstants) {
          setEnabled(false);
        }
      }
    },
    {
      queryKey: `${ogmioToken} Seniority`,
      queryFn: ogmioGetAllSeniorityServiceRest,
      enabled: (enabled && ogmioToken != '') ? true : false,
      onSuccess: (data: any) => {
        if (data.data.getAllHefestoPodConstants) {
          setEnabled(false);
        }
      }
    }
  ]);
  if (results[0].status == 'success' && results[1].status == 'success') {
    safeSetItem('sharedDropdownValues', JSON.stringify(results));
  }

  return (
    <div className="bg-gray-200 text-gray-300 min-h-screen">
      { ( (results[0].status == 'success' && results[1].status == 'success') && ogmioToken !== '') && <MemberViewAllTable ogmioToken={ogmioToken} />}
    </div>
  );
}

function safeSetItem(key: any, value: any) {
  try {
    localStorage.setItem(key, value);
  } catch (e: any) {
    if (e.name === 'QuotaExceededError') {
      console.error('Quota exceeded! Clearing local storage.');
      localStorage.removeItem(key);
      // Try again
      localStorage.setItem(key, value);
    } else {
      console.error('An unknown error occurred: ', e);
    }
  }
}