import * as React from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import '../tailwind.scss';
import TooltipLabelComponent from './tooltip-label-component';
import IconNotification from './icon-information';

export default function ToggleComponent<T>(props: ToggleProps<T>): JSX.Element {
  const [isChecked, setIsChecked] = React.useState(props.isChecked ?? false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    props.changeMethod(event, props.notToggleable || false);
  };

  return (
    <>
      <div>
        <label className="mt-4 inline-flex relative items-center cursor-pointer ">
          <input
            id={props.name}
            type="checkbox"
            defaultChecked={isChecked}
            disabled={props.disabled}
            className="sr-only peer"
            {...(props.route && props.register ? { ...props.register(props.route) } : {})}
            onChange={handleChange}
          />
          <div
            className="ring-4 w-11 h-6
            bg-vulcanus_paynes_gray
            dark:bg-vulcanus_cool_gray-500
            rounded-full
            peer
            peer-focus:ring-4
            peer-focus:ring-vulcanus_paynes_gray
            dark:peer-focus:ring-vulcanus_paynes_gray
            peer-checked:after:translate-x-full
            peer-checked:after:border-vulcanus_ghost_white-500
            after:content-['']
            after:absolute
            after:top-0.5
            after:left-[2px]
            after:bg-vulcanus_ghost_white-500
            after:border-vulcanus_paynes_gray
            after:rounded-full
            after:h-5
            after:w-5
            after:transition-all
            peer-checked:bg-vulcanus_paynes_gray
            checked:after:border-vulcanus_ghost_white-500"
          />

          <TooltipLabelComponent message={`Should this member be registered in ${props.name} `} value={props.name} />
          {notificacionMessage(isChecked, props.expectedValueBoolean)}
        </label>
      </div>
    </>
  );
}

export const notificacionMessage = (actual: any, expected: any) => {
  return expected == null ? (
    <></>
  ) : (
    <IconNotification message={expected + ' is expected for this pod'} value={'' + !actual} />
  );
};

export interface ToggleProps<T> {
  route?: Path<T> | undefined;
  name: string;
  register?: UseFormRegister<T> | undefined;
  isChecked?: boolean;
  changeMethod: (event: React.ChangeEvent<HTMLInputElement>, b: boolean) => void;
  disabled: boolean;
  notToggleable?: boolean;
  expectedValueBoolean: any;
}
