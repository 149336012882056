export const FIRST_NAME = 'First Name';
export const NAMES = 'Names';
export const SURNAMES = 'Surnames';
export const FIRST_SURNAME = 'First Surname';
export const SECOND_NAME = 'Second Name';
export const SECOND_SURNAME = 'Second Surname';
export const EMAIL = 'Email';
export const PROJECT_ID = 'Project id';
export const GITHUB_USER_NAME = 'Github EMU Username';
export const POD = 'Pod';
export const SENIORITY = 'Seniority';
export const COMPANY_NAME = 'Company Name';
export const MEMBERS = 'Members';
export const ROWS_PER_PAGE = 'Rows per page';
export const FROM_VAL = 'from';
export const SELECT_ALL = 'Select All';
export const SUCCESS = 'Success';
export const ERROR = 'ERROR';
export const CREATE_MEMBER_TEXT = 'Create New Member';
export const MEMBER_WITH_ID = 'Member with id';
export const MESSAGE_DELETE_SUCCESSFUL = 'was deleted successfully with all related components';
export const MESSAGE_UPDATE_SUCCESSFUL = 'was updated successfully with all related components';
export const MESSAGE_DELETE_UN_SUCCESSFUL = 'was not deleted successfully';
export const MESSAGE_UPDATE_UN_SUCCESSFUL = 'was not updated successfully';
export const MESSAGE_CREATE_SUCCESSFUL = 'was created successfully with all related components';
export const MESSAGE_CREATE_UNSUCCESSFUL = 'The following errors were presented during Member Creation:';
export const DELETE_MEMBER_RESULT = 'Delete Member Result';
export const UPDATE_MEMBER_RESULT = 'Update Member Result';
export const CREATE_MEMBER_RESULT = 'Create Member Result';
export const MESSAGE_CONFIRM_DELETE = 'Are you sure you want to delete';
export const THIS_ACTION_CANT_BE_UNDONE = 'This action cannot be undone';
export const PLEASE_PROVIDE_MEMBER_INFORMATION = 'Please provide members information.';
export const CLOSE_MENU = 'Close menu';
export const CREATE_MEMBER = 'CLOSE_MENU';
export const SEARCH_PLACEHOLDER = 'Search';
export const SEARCH_BY_TEXT = 'Search by';
export const SEARCH_BUTTON_TEXT = 'Search';
export const CLEAR_BUTTON_TEXT = 'x';
export const SERVICES_TITLE = 'Services';
export const ROLES_TITLE = 'Roles';
export const COMPANIES = [
  {
    id: 'ADL',
    value: {
      plural: 'ADL'
    }
  },
  {
    id: 'Banco de Bogota',
    value: {
      plural: 'Banco de Bogota'
    }
  },
  {
    id: 'Banco de Occidente',
    value: {
      plural: 'Banco de Occidente'
    }
  },
  {
    id: 'Banco Av Villas',
    value: {
      plural: 'Banco Av Villas'
    }
  },
  {
    id: 'Banco Popular',
    value: {
      plural: 'Banco Popular'
    }
  },
  {
    id: 'PTEC',
    value: {
      plural: 'PTEC'
    }
  }
];

export const SONARQUBE = 'Sonarqube';
export const GITHUB_EMU = 'Github Emu';
export const GITHUB = 'Github';
export const ARTIFACTORY = 'Artifactory';
export const IS_DEVOPS = 'Is Devops';
export const IS_TRANSVERSAL = 'Is Transversal';
export const SAVE = 'Save';
export const COLUMNS = [
  {
    id: 'email',
    value: {
      plural: 'Email'
    }
  },
  {
    id: 'firstName',
    value: {
      plural: 'First Name'
    }
  },
  {
    id: 'firstSurname',
    value: {
      plural: 'First Surname'
    }
  },
  {
    id: 'secondName',
    value: {
      plural: 'Second Name'
    }
  },
  {
    id: 'secondSurname',
    value: {
      plural: 'Second Surname'
    }
  },
  {
    id: '_id',
    value: {
      plural: 'ID'
    }
  }
];
