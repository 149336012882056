import { useReducer } from 'react';

export interface ToggleState {
  artifactory: boolean;
  github: boolean;
  sonar: boolean;
}

const initialState = {
  artifactory: false,
  github: false,
  sonar: false
};

type ActionUpdates = Record<string, Partial<ToggleState>>;

function reducer(state: ToggleState, action: { type: string }) {
  const actionUpdates: ActionUpdates = {
    turnOnAll: {
      artifactory: true,
      github: true,
      sonar: true
    },
    turnOffAll: {
      artifactory: false,
      github: false,
      sonar: false
    },
    toggleArtifactory: { artifactory: !state.artifactory },
    toggleGithub: { github: !state.github },
    toggleSonar: { sonar: !state.sonar }
  };

  return { ...state, ...actionUpdates[action.type] };
}

export const useToggleReducer = (): Array<any | (() => void)> => {
  const [toggleStatus, dispatch] = useReducer(reducer, initialState);

  const turnOnAll = () => dispatch({ type: 'turnOnAll' });
  const turnOffAll = () => dispatch({ type: 'turnOffAll' });
  const toggleArtifactory = () => dispatch({ type: 'toggleArtifactory' });
  const toggleGithub = () => dispatch({ type: 'toggleGithub' });
  const toggleSonar = () => dispatch({ type: 'toggleSonar' });

  return [toggleStatus, turnOnAll, turnOffAll, toggleArtifactory, toggleGithub, toggleSonar];
};
