import axios from 'axios';
import * as React from 'react';
import { MemberInput } from '../member-interfaces';

export const memberCreateService = async (memberInput: MemberInput) => {
  const config = {
    headers: {
      Authorization: memberInput.authorization,
      'Content-Type': 'application/graphql'
    }
  };
  const input = processInput(memberInput);
  console.log('input post processed');
  console.log(input);
  const graphql = JSON.stringify({
    query: createUserHefestoMutation,
    variables: input
  });

  const ULR = process.env.GRAPHQLURL !== undefined ? process.env.GRAPHQLURL : '';
  const { data: response } = await axios.post(ULR, graphql, config);
  return response;
};

const createUserHefestoMutation = `
  mutation createUserHefesto($input: CreateUserHefestoInput!) {
    createUserHefesto(input: $input) {
      errors {
        message
        type
        data {
          field
          message
        }
      }
      user {
        email
        firstName
        firstSurname
        id
        tools {
          name
          value
        }
      }
      statusCode
    }
  }
`;

function processInput(memberInput: MemberInput): MemberInput {
  const input = { ...memberInput };
  input.authorization = '';

  // Trim strings in ogmioData
  input.input.ogmioData.firstName = input.input.ogmioData.firstName.trim();
  input.input.ogmioData.firstSurname = input.input.ogmioData.firstSurname.trim();

  if (input.input.ogmioData.secondName) {
    input.input.ogmioData.secondName = input.input.ogmioData.secondName.trim();
  }
  if (input.input.ogmioData.secondSurName) {
    input.input.ogmioData.secondSurName = input.input.ogmioData.secondSurName.trim();
  }

  input.input.ogmioData.email = input.input.ogmioData.email.trim();

  // Modify the tools
  input.input.ogmioData.tools[0].name = 'sonarqube';
  input.input.ogmioData.tools[0].value = input.input?.ogmioData?.tools[0].value.toString();

  input.input.ogmioData.tools[1].name = 'emu';
  input.input.ogmioData.tools[1].value = input.input?.ogmioData?.tools[1].value.toString();

  input.input.ogmioData.tools[2].name = 'artifactory';
  input.input.ogmioData.tools[2].value = input.input?.ogmioData?.tools[2].value.toString();
  return input;
}
