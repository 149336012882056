import axios from 'axios';
import * as React from 'react';
import { Member } from '../member/member-view-all-table';

export const updateMemberService = async (memberInput: Member) => {
  const config = {
    headers: {
      Authorization: memberInput.authorization,
      'Content-Type': 'application/graphql'
    }
  };
  const input = wrapMemberInInput(processInput(memberInput));
  const graphql = JSON.stringify({
    query: updateUserHefestoMutation,
    variables: input
  });

  const ULR = process.env.GRAPHQLURL !== undefined ? process.env.GRAPHQLURL : '';
  const { data: response } = await axios.post(ULR, graphql, config);
  return response;
};

const updateUserHefestoMutation = `
  mutation updateUserHefesto($input: UpdateUserHefestoInput!) {
  updateUserHefesto(input: $input) {
    errors {
      message
      type
      data {
        field
        message
      }
    }
    statusCode
  }
}
`;

function wrapMemberInInput(member: Member): { input: Partial<Member> } {
  const { githubEmuUser, address, phones, authorization, ...rest } = member;
  return { input: rest };
}

function processInput(memberInput: Member): Member {
  const input = { ...memberInput };
  input.authorization = '';

  // Trim strings in ogmioData
  input.firstName = input.firstName.trim();
  input.firstSurname = input.firstSurname.trim();

  if (input.secondName) {
    input.secondName = input.secondName.trim();
  }

  if (input.secondSurName) {
    input.secondSurName = input.secondSurName.trim();
  }
  return input;
}
