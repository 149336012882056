import * as React from 'react';
import '../tailwind.scss';
import TitleComponent from './title-component';

export default function RowInfoComponent(props: BulbProps): JSX.Element {
  return (
    <div className="mx-3 flex-auto">
      <h3 className={`flex justify-center text-xs font-bold text-center text-black`}>{props.tittle}</h3>
      <div className={'text-xs flex justify-center'}>{props.value}</div>
    </div>
  );
}

export interface BulbProps {
  tittle: string;
  value: string;
}
