import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import { useQuery, useQueries, useQueryClient } from 'react-query';
import { BulbProps } from '../../common-components/bulb-component';
//import ButtonMainComponent from '../../common-components/button-main';
import CustomLoader from '../../common-components/custom-loader';
import { Notification3 } from '../../common-components/notifications';
import {
  CREATE_MEMBER_TEXT,
  DELETE_MEMBER_RESULT,
  EMAIL,
  FROM_VAL,
  MEMBER_WITH_ID,
  MEMBERS,
  MESSAGE_DELETE_SUCCESSFUL,
  NAMES,
  PROJECT_ID,
  ROWS_PER_PAGE,
  SELECT_ALL,
  SURNAMES
} from '../../constants';
import '../../tailwind.scss';
import { membersGetAllService } from '../services/members-get-all-service';
import FilterComponent from './filter-componet';
import MemberCreateComponent from './member-create-component';
import MemberDeleteComponent from './member-delete-component';
import { MemberExpandableComponent } from './member-expandable-component';
import MemberUpdateComponent from './member-update-component';

const customStyles = {
  headCells: {
    style: {
      color: 'text-blue'
    }
  },
  columnheader: {
    style: {
      fontSize: 'text-xs font-bold',
      color: 'text-black'
    }
  },
  rows: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: 'bg-vulcanus_cool_gray-500'
    }
  },
  expanderRow: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'bg-vulcanus_cool_gray-500'
    }
  },
  headRow: {
    style: {
      fontSize: '15px',
      fontStyle: 'font-bold'
    }
  }
};

export default function MemberViewAllTable(props: ViewAllProps): JSX.Element {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKey, setSelectedKey] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const [dataState, setDataState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [isCreationOpen, setIsCreationOpen] = useState(false);

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(selectedValue);
  const queryClient = useQueryClient();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [getAllEnabled, setGetAllEnabled] = useState(true);

  const removeItemFromDataState = (id: string) => {
    setNotificationMessage(`${MEMBER_WITH_ID} ${id} ${MESSAGE_DELETE_SUCCESSFUL}`);
    setIsSuccess(true);
    setShowNotification(true);
    queryClient.invalidateQueries(['memberData', page, perPage, props.ogmioToken, selectedKey, selectedValue]);
  };
  const queryKey = ['memberData', page, perPage, props.ogmioToken, selectedKey, selectedValue];

  const memberQueryAll = useQuery(
    queryKey,
    ({ queryKey: [key, queryPage, queryPerPage, auth, filterKey, filterValue] }) => {
      const aPage = queryPage as number;
      const aPerPage = queryPerPage as number;
      const authKey = auth as string;
      const filterK = filterKey as string;
      const filterVal = filterValue as string;

      return membersGetAllService({
        page: aPage,
        p_limit: aPerPage,
        auth: authKey,
        filter: {
          key: filterK,
          value: filterVal
        }
      });
    },
    {
      //enabled: getAllEnabled,
      onSuccess: data => {
        setLoading(false);
        setDataState(data.data?.getAllUsers.users ?? []);
        setTotalRows(data.data?.getAllUsers.total);
        setGetAllEnabled(false);
      },
      onError: (e: string) => {
        console.error({
          title: 'Error',
          message: e,
          active: true
        });
      }
    }
  );

  const columns = [
    {
      name: NAMES,
      selector: (member: Member) => (member.secondName ? member.firstName + ' ' + member.secondName : member.firstName),
      sortable: true,
      reorder: true,
      hide: 1000
    },
    {
      name: SURNAMES,
      selector: (member: Member) =>
        member.secondSurName ? member.firstSurname + ' ' + member.secondSurName : member.firstSurname,
      sortable: true,
      reorder: true
    },
    {
      name: EMAIL,
      selector: (member: Member) => member.email,
      sortable: true,
      reorder: true
    },
    {
      name: 'Options',
      cell: (row: Member) => (
        <>
          <MemberDeleteComponent
            content={'Are you sure you DELETE this member?'}
            header="Delete Member"
            member={row}
            auth={props.ogmioToken}
            parentState={removeItemFromDataState}
          />
          <MemberUpdateComponent
            content={'Are you sure you DELETE this member?'}
            header="Delete Member"
            member={row}
            auth={props.ogmioToken}
            parentState={removeItemFromDataState}
            ogmioToken={props.ogmioToken}
          />
        </>
      )
    }
  ];
  const handlePageChange = async (newPage: number) => {
    setGetAllEnabled(true);
    setPage(newPage);
    setLoading(true);
    await queryClient.invalidateQueries(['memberData', newPage, perPage, props.ogmioToken, selectedKey, selectedValue]);
  };
  const handlePerRowsChange = async (newPerPage: number) => {
    setGetAllEnabled(true);
    // here i could add page as parameter and the library will bring it
    setLoading(true);
    setPerPage(newPerPage);
    await queryClient.invalidateQueries(['memberData', page, newPerPage, props.ogmioToken, selectedKey, selectedValue]);
  };
  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map((member: Member) => member.email)}?`)) {
      }
    };

    return (
      <></>
      // <label>
      //   <input
      //     className='w-full px-6 py-2 my-4 text-white bg-red-600 rounded-lg hover:bg-red-900'
      //     type='submit'
      //     value={'Delete'}
      //   />
      // </label>
    );
  }, [
    // dataState,
    selectedRows
  ]);

  const handleRowSelected = (state: { allSelected: boolean; selectedCount: number; selectedRows: never[] }) => {
    setSelectedRows(state.selectedRows);
  };
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const handleSubmit = (key: string, value: string) => {
      setLoading(true);
      setSelectedKey(key);
      setSelectedValue(value);
      if (value == '') {
        setGetAllEnabled(true);
      }
    };

    return (
      <FilterComponent
        onFilter={(e: React.FormEvent<HTMLInputElement>) => {
          setFilterText(e.currentTarget.value);
        }}
        onClear={handleClear}
        filterText={filterText}
        onSubmit={handleSubmit}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    //const timer = setTimeout(() => setGetAllEnabled(true), 2000);
    return () => {
      //clearTimeout(timer);
      queryClient.invalidateQueries(['memberData', page, perPage, props.ogmioToken, selectedKey, debouncedValue]);
    };
  }, [selectedValue]);

  useEffect(() => {
    queryClient.invalidateQueries(['memberData', page, perPage, props.ogmioToken, selectedKey, debouncedValue]);
  }, [debouncedValue, selectedKey, selectedValue]);

  return (
    <div className="flex items-center justify-center h-screen w-screen pt-16">
      {showNotification && (
        <div className="fixed z-10 w-3/12 h-8/12 top-12">
          <Notification3
            title={DELETE_MEMBER_RESULT}
            message={notificationMessage}
            isSuccess={isSuccess}
            open={showNotification}
            setOpen={setShowNotification}
            notificationPosition={'relative'}
          />
        </div>
      )}
      <div className="h-screen w-11/12">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">{subHeaderComponentMemo}</div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {/* <ButtonMainComponent methodChange={() => setIsCreationOpen(!isCreationOpen)} Name="+" /> */}
            <button
                    className="btn-approve px-4 py-1 rounded"
                    onClick={() => setIsCreationOpen(!isCreationOpen)}
                  >
                    +
                  </button>
            {isCreationOpen && props.ogmioToken !== '' ? (
              <MemberCreateComponent
                changeMethod={() => setIsCreationOpen(!isCreationOpen)}
                state={isCreationOpen}
                ogmioToken={props.ogmioToken}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="mt-8 flow-root bg-white rounded-xl">
          <div className="m-8">
            <DataTable
              columns={columns}
              contextActions={contextActions}
              data={dataState}
              expandableRows={true}
              expandOnRowClicked={true}
              expandableRowsComponent={(member: ExpanderComponentProps<Member>) => (
                <MemberExpandableComponent
                  member={member.data}
                  ogmioToken={props.ogmioToken}
                />
              )}
              highlightOnHover
              pointerOnHover
              progressPending={loading}
              progressComponent={<CustomLoader height={'200'} width={'200'} color={'vulcanus_delft_blue'} />}
              paginationResetDefaultPage={resetPaginationToggle}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationComponentOptions={paginationOptions}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export interface Member {
  id: string;
  firstName: string;
  secondName: string;
  firstSurname: string;
  secondSurName: string;
  company: string;
  email: string;
  stack: string;
  layer: string;
  companyName: string;
  tools: BulbProps[];
  githubUser: string;
  roles: Role[];
  components: string;
  authorization?: string;
  githubEmuUser: string;
  phones?: string;
  address?: string;
}

export interface Role {
  role: string;
  projectID: string;
}

const paginationOptions = {
  rowsPerPageText: ROWS_PER_PAGE,
  rangeSeparatorText: FROM_VAL,
  selectAllRowsItem: true,
  selectAllRowsItemText: SELECT_ALL
};

export interface ViewAllProps {
  ogmioToken: string;
}
