import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { DropDownItem } from '../../common-components/dropdown-component';
import { FormSection } from '../../common-components/generic-form-component';
import {
  ARTIFACTORY,
  COMPANIES,
  COMPANY_NAME,
  EMAIL,
  FIRST_NAME,
  FIRST_SURNAME,
  GITHUB,
  IS_DEVOPS,
  IS_TRANSVERSAL,
  PLEASE_PROVIDE_MEMBER_INFORMATION,
  POD,
  SECOND_NAME,
  SECOND_SURNAME,
  SENIORITY,
  SONARQUBE
} from '../../constants';
import { useToggleReducer } from '../../hooks/toggleReducer';
import { MemberInput } from '../member-interfaces';

export const createFormSections = (
  podsArray: DropDownItem[],
  seniorityArray: DropDownItem[],
  transversalPodsArray: DropDownItem[]
): FormSection[] => {
  const {
    formState: { errors }
  } = useForm<MemberInput>();
  const [isDevOpsState, setDevOpsState] = useState<boolean>(false);
  const [isTransversalState, setTransversalOpsState] = useState<boolean>(false);
  const [toggleStatus, turnOnAll, turnOffAll, toggleArtifactory, toggleGithub, toggleSonar] = useToggleReducer();
  const [expectedArtifactoryValue, setExpectedArtifactoryValue] = useState<boolean>();
  const [expectedEmuValue, setExpectedEmuValue] = useState<boolean>();
  const [expectedSonarqubeValue, setExpectedSonarqubeValue] = useState<boolean>();
  return [
    {
      title: '',
      description: PLEASE_PROVIDE_MEMBER_INFORMATION,
      fields: [
        {
          changeMethod: () => {},
          route: 'input.ogmioData.firstName',
          id: FIRST_NAME,
          name: FIRST_NAME,
          label: '',
          type: 'text',
          autoComplete: 'family-name',
          placeholder: 'Enter your first name',
          required: true,
          section: 'personal-information'
        },
        {
          changeMethod: () => {},
          route: 'input.ogmioData.secondName',
          id: SECOND_NAME,
          name: SECOND_NAME,
          label: '',
          type: 'text',
          autoComplete: 'additional-name',
          placeholder: 'Enter your second name',
          required: false,
          section: 'personal-information'
        },
        {
          changeMethod: () => {},
          route: 'input.ogmioData.firstSurname',
          id: FIRST_SURNAME,
          name: FIRST_SURNAME,
          label: '',
          type: 'text',
          autoComplete: 'given-name',
          placeholder: 'Enter your first name',
          required: true,
          section: 'personal-information'
        },

        {
          changeMethod: () => {},
          route: 'input.ogmioData.secondSurName',
          id: SECOND_SURNAME,
          name: SECOND_SURNAME,
          label: '',
          type: 'text',
          autoComplete: 'family-name',
          placeholder: 'Enter your second surname',
          required: false,
          section: 'personal-information'
        },
        {
          changeMethod: () => {},
          route: 'input.ogmioData.email',
          id: EMAIL,
          name: EMAIL,
          label: '',
          type: 'email',
          autoComplete: 'email',
          placeholder: 'Enter your email',
          required: true,
          section: 'personal-information'
        },
        {
          changeMethod: () => {},
          route: 'input.ldapData.company',
          placeholder: 'my company',
          id: COMPANY_NAME,
          name: COMPANY_NAME,
          label: COMPANY_NAME,
          type: 'select',
          autoComplete: 'off',
          required: true,
          options: COMPANIES,
          section: 'personal-information'
        },
        {
          changeMethod: () => {
            if (!isDevOpsState) {
              setTransversalOpsState(!isTransversalState);
            }
          },
          route: 'input.roleData.isTransversal',
          placeholder: '',
          id: IS_TRANSVERSAL,
          name: '',
          label: IS_TRANSVERSAL,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          colSpan: 2,
          locked: isDevOpsState,
          section: 'role-information'
        },
        {
          changeMethod: () => {
            if (!isTransversalState) {
              setDevOpsState(!isDevOpsState);
            }
          },
          route: 'input.roleData.isDevops',
          placeholder: '',
          id: IS_DEVOPS,
          name: '',
          label: IS_DEVOPS,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          colSpan: 2,
          locked: isTransversalState,
          section: 'role-information'
        },

        {
          changeMethod: event => {
            const tools = transversalPodsArray.filter((element: { id: string }) => element.id === event?.target.value);
            setExpectedArtifactoryValue(extractToolValue('artifactory', tools[0].value.tools));
            setExpectedEmuValue(extractToolValue('emu', tools[0].value.tools));
            setExpectedSonarqubeValue(extractToolValue('sonarqube', tools[0].value.tools));
          },
          route: 'input.roleData.pod',
          placeholder: '',
          id: POD,
          name: POD,
          label: POD,
          type: 'select',
          autoComplete: 'off',
          required: true,
          options: transversalPodsArray || [],
          isHidden: !isTransversalState,
          section: 'role-information'
        },
        ///////////////////////////////////
        {
          changeMethod: () => {},
          route: 'input.roleData.pod',
          placeholder: '',
          id: POD,
          name: POD,
          label: POD,
          type: 'select',
          autoComplete: 'off',
          required: true,
          options: podsArray || [],
          isHidden: !isDevOpsState,
          section: 'role-information'
        },
        {
          changeMethod: () => {},
          route: 'input.roleData.seniority',
          placeholder: 'my seniority',
          id: SENIORITY,
          name: SENIORITY,
          label: SENIORITY,
          type: 'select',
          autoComplete: 'off',
          required: true,
          options: seniorityArray || [],
          isHidden: !isDevOpsState,
          section: 'role-information'
        },
        {
          changeMethod: toggleSonar,
          route: 'input.ogmioData.tools.0.value',
          placeholder: '',
          id: SONARQUBE,
          name: '',
          label: SONARQUBE,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          expectedValueBoolean: expectedSonarqubeValue,
          section: 'tool-information'
        },
        {
          changeMethod: toggleGithub,
          route: 'input.ogmioData.tools.1.value',
          placeholder: '',
          id: GITHUB,
          name: '',
          label: GITHUB,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          expectedValueBoolean: expectedEmuValue,
          section: 'tool-information'
        },
        {
          changeMethod: toggleArtifactory,
          route: 'input.ogmioData.tools.2.value',
          placeholder: '',
          id: ARTIFACTORY,
          name: '',
          label: ARTIFACTORY,
          type: 'checkbox',
          autoComplete: 'off',
          required: false,
          expectedValueBoolean: expectedArtifactoryValue,
          section: 'tool-information'
        }
      ],
      categories: [
        {
          id: 'personal-information',
          title: 'Personal Information',
          col_span: '2'
        },
        {
          id: 'tool-information',
          title: 'Tools Information',
          col_span: '3'
        },
        {
          id: 'role-information',
          title: 'Roles Information',
          col_span: '2'
        }
      ]
    }
  ];
};

const extractToolValue = (toolName: string, jsonData: any) => {
  const tool = jsonData.find((t: { name: string }) => t.name === toolName);
  return tool ? tool.value === 'true' : false;
};
